export default function validatePostalCode(country, postalCode) {
  if (!country || !postalCode) {
    return true;
  }
  const countryCode = country.trim().toUpperCase();
  const rule = rules[countryCode];
  if (!rule) {
    return true;
  }
  const value = normalizePostalCode(postalCode);

  if (value.match(rule.regex)) {
    return { valid: true };
  } else {
    return { valid: false, error: rule.message };
  }
}

export function normalizePostalCode(postalCode) {
  return postalCode
    .trim()
    .toUpperCase()
    .replace(/\s+/g, " ")
    .replace(/[^A-Z0-9 ]/g, "");
}

const msgThree = "must be three digits";
const msgFour = "must be four digits";
const msgFive = "must be five digits";
const msgSix = "must be six digits";
const rules = {
  AD: {
    regex: /^(AD)?[0-9]{3}$/,
    message: "must begin with A or D followed by three digits"
  },
  AF: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  AI: {
    regex: /^AI-2640$/,
    message: "must be AI-2640"
  },
  AL: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  AM: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  AR: {
    regex: /^(?:([0-9]{4})|([A-Z][0-9]{4}[A-Z]{3}))$/,
    message: "must be either four digits or a letter four digits and three letters"
  },
  AS: {
    regex: /^(?:[0-9]{5}(\-[0-9]{4}|\-[0-9]{6})?)$/,
    message:
      "must be start with five digits and optionally be followed by a dash and either four or six digits"
  },
  AT: {
    regex: /^(A-|AT-)?[0-9]{4}$/,
    message: "must be A- followed by four digits or AT- followed by four digits"
  },
  AU: {
    regex: /^(?:(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2}))$/,
    message: "must be 4 digits and be valid"
  },
  AX: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  AZ: {
    regex: /^(AZ)?[0-9]{4}$/,
    message: "must be four digits optionally prefixed by AZ"
  },
  BA: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  BB: {
    regex: /^(BB)?[0-9]{5}$/,
    message: "must be five digits optionally prefixed by BB"
  },
  BD: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  BE: {
    regex: /^[1-9]{1}[0-9]{3}$/,
    message: "must be four digits but must not start with a zero"
  },
  BG: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  BH: {
    regex: /^[0-9]{3,4}$/,
    message: "must be three or four digits"
  },
  BL: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  BM: {
    regex: /^[A-Z]{2} {0,1}(?:([0-9]{2})|([A-Z]{2}))$/,
    message:
      "must start with two letters followed by an optional space and either two digits or two more letters"
  },
  BN: {
    regex: /^[A-Z]{2}[0-9]{4}$/,
    message: "must start with two letters followed by four digits"
  },
  BO: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  BR: {
    regex: /^[0-9]{5}-?[0-9]{3}$/,
    message:
      "postal code must be five digits followed by an optional - and three more digits"
  },
  BT: {
    regex: /^[0-9]{3}$/,
    message: msgThree
  },
  BY: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  CA: {
    regex: /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/,
    message: "must be of the format A0A 0A0 or A0A0A0 and be valid"
  },
  CC: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  CH: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  CL: {
    regex: /^[0-9]{3}-?[0-9]{4}$/,
    message: "must be three digits followed by an optional - and four more digits"
  },
  CN: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  CO: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  CR: {
    regex: /^[0-9]{4,5}$/,
    message: "must be either four or five digits"
  },
  CU: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  CV: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  CX: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  CY: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  CZ: {
    regex: /^[0-9]{3} ?[0-9]{2}$/,
    message: "bust be five digits with an optional space after the third digit"
  },
  DE: {
    regex: /\b((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))\b/,
    message: "invalid"
  },
  DK: {
    regex: /^([Dd][Kk])?( |-)?[1-9]{1}[0-9]{3}$/,
    message:
      "must be four digits but the first digit cannot be zero and it can optionally start with the prefix DK"
  },
  DO: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  DZ: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  EC: {
    regex: /^(EC)?[0-9]{6}$/,
    message: "must be six digits with an optional prefix of EC"
  },
  EE: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  EG: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  ES: {
    regex: /^(?:([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3})$/,
    message: "must be five digits and be valid"
  },
  ET: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  FI: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  FK: {
    regex: /^FIQQ 1ZZ$/,
    message: "must be FIQQ 1ZZ"
  },
  FM: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  FO: {
    regex: /^[0-9]{3}$/,
    message: msgThree
  },
  FR: {
    regex: /^(F-)?((2[AB])|[0-9]{2})[0-9]{3}$/,
    message:
      "must be either five digits or (2[AB] followed by three digits) in either case it may be prefixed with an optional F-"
  },
  GA: {
    regex: /^[0-9]{2}([A-Z0-9\ \-\.])*[0-9]{2}$/,
    message: "must be two digits followed by the city name and two more digits"
  },
  GB: {
    regex: /^(?:(GIR|[A-Z]\d[A-Z\d]??|[A-Z]{2}\d[A-Z\d]??)[ ]??(\d[A-Z]{2}))$/,
    message: "invalid"
  },
  UK: {
    regex: /^(?:(GIR|[A-Z]\d[A-Z\d]??|[A-Z]{2}\d[A-Z\d]??)[ ]??(\d[A-Z]{2}))$/,
    message: "invalid"
  },
  GE: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  GF: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  GG: {
    regex: /^[A-Z]{2}[0-9]{1,2} ?[0-9][A-Z]{2}$/,
    message: "invalid"
  },
  GI: {
    regex: /^GX11 1AA$/,
    message: "must be GX11 1AA"
  },
  GL: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  GP: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  GR: {
    regex: /^[0-9]{3} ?[0-9]{2}$/,
    message: "must be five digits with an optional space after the third digit"
  },
  GS: {
    regex: /^SIQQ 1ZZ$/,
    message: "must be SIQQ 1ZZ"
  },
  GT: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  GU: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  GW: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  HM: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  HN: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  HR: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  HT: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  HU: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  ID: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  IL: {
    regex: /^[0-9]{5}([0-9]{2})?$/,
    message: "must be either five digits or seven digits"
  },
  IM: {
    regex: /^(IM)?[0-9]{1,2} ?[0-9][A-Z]{2}$/,
    message:
      "must be one or two digits with an optional space then one digit and two letters.  It may have a prefix of IM"
  },
  IN: {
    regex: /^[0-9]{3} ?[0-9]{3}$/,
    message: "must be six digits with an optional space after the third digit"
  },
  IO: {
    regex: /^BBND 1ZZ$/,
    message: "must be BBND 1ZZ"
  },
  IQ: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  IR: {
    regex: /^[0-9]{5}( |-)?[0-9]{5}$/,
    message: "must be ten digits with an optional space or - after the fifth digit"
  },
  IS: {
    regex: /^[0-9]{3}$/,
    message: msgThree
  },
  IT: {
    regex: /^(V-|I-)?([0-9]{5}|[0-9]{4})$/,
    message: "must be five digits and may have an optional prefix of either V- or I-"
  },
  JE: {
    regex: /^(JE)?[0-9] ?[0-9][A-Z]{2}$/,
    message:
      "must be one digit an optional space one digit and two letters.  It may have a prefix of JE"
  },
  JM: {
    regex: /^((JM)?[A-Z]{3}[0-9]{2}|[0-9]{2})$/,
    message:
      "must either be two digits or (three letters and two digits with an optional prefix of JM)"
  },
  JO: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  JP: {
    regex: /^[0-9]{3}-?[0-9]{4}$/,
    message: "must be seven digits with an optional - after the third digit"
  },
  KE: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  KG: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  KH: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  KR: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  KW: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  KY: {
    regex: /^KY[123]{1}-{0,1}([0-9]{4})$/,
    message: "must start with KY then 1, 2 or 3, an optional - and four digits"
  },
  KZ: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  LA: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  LB: {
    regex: /^[0-9]{4}( |-)?[0-9]{4}$/,
    message:
      "must be eight digits with an optional space or - after the fourth digit"
  },
  LI: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  LK: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  LR: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  LS: {
    regex: /^[0-9]{3}$/,
    message: msgThree
  },
  LT: {
    regex: /^(LT-)?[0-9]{5}$/,
    message: "must be five digits with an optional LT- prefix"
  },
  LU: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  LV: {
    regex: /^(LV-)?[0-9]{4}$/,
    message: "must be four digits with an optional LV- prefix"
  },
  LY: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  MA: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  MC: {
    regex: /^(980)[0-9]{2}$/,
    message: "must be 980NN"
  },
  MD: {
    regex: /^(MD-?)?[0-9]{4}$/,
    message: "must be four digits with an optional MD- prefix"
  },
  ME: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  MG: {
    regex: /^[0-9]{3}$/,
    message: msgThree
  },
  MH: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  MK: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  MM: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  MN: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  MP: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  MQ: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  MT: {
    regex: /^[A-Z]{3} ?[0-9]{4}$/,
    message: "must be three letters, an optional space and four digits"
  },
  MV: {
    regex: /^[0-9]{4,5}$/,
    message: "postal code must be either four or five digits"
  },
  MX: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  MY: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  MZ: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  NA: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  NC: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  NE: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  NF: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  NG: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  NI: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  NL: {
    regex: /^[1-9][0-9]{3}\s?([a-zA-Z]{2})?$/,
    message:
      "must be four digits but must not start with a zero, and it may be followed by an optional space and two more letters"
  },
  NO: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  NP: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  NZ: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  OM: {
    regex: /^[0-9]{3}$/,
    message: msgThree
  },
  PA: {
    regex: /^|\d{4}(-\d{4})?|\d{6}|\d{8}$/,
    message:
      "must be 0,4,6 or 8 digits with optional - after the fourth digit in 8 digits format"
  },
  PE: {
    regex: /^[0-2][0-9]{4}$/,
    message: "must be five digits but the first digit must be 0,1 or 2"
  },
  PF: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  PG: {
    regex: /^[0-9]{3}$/,
    message: msgThree
  },
  PH: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  PK: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  PL: {
    regex: /^[0-9]{2}-?[0-9]{3}$/,
    message:
      "must be five digits but there may be an optional - after the second digit"
  },
  PM: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  PN: {
    regex: /^PCRN 1ZZ$/,
    message: "must be PCRN 1ZZ"
  },
  PR: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  PS: {
    regex: /^[0-9]{3}$/,
    message: msgThree
  },
  PT: {
    regex: /^[0-9]{4}(-| )?[0-9]{3}$/,
    message:
      "must be seven digits with an optional space or - after the fourth digit"
  },
  PW: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  PY: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  RE: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  RO: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  RS: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  RU: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  SA: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  SD: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  SE: {
    regex: /^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/,
    message:
      "must be five digits with an optional space after the third digit and may have a prefix of S- or s-"
  },
  SG: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  SH: {
    regex: /^(TDCU|STHL)\s?1ZZ$/,
    message: "must be TDCU 1ZZ or STHL 1ZZ"
  },
  SI: {
    regex: /^(SI-)?[0-9]{4}$/,
    message: "must be four digits with an optional prefix of SI-"
  },
  SK: {
    regex: /^[0-9]{3} ?[0-9]{2}$/,
    message: "must be five digits with an optional space after the third digit"
  },
  SM: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  SN: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  SV: {
    regex: /^[0-9]{4,5}$/,
    message: "postal code must be either four or five digits"
  },
  SZ: {
    regex: /^[A-Z][0-9]{3}$/,
    message: "must be a letter followed by three digits"
  },
  TC: {
    regex: /^TKCA 1ZZ$/,
    message: "must be TKCA 1ZZ"
  },
  TD: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  TH: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  TJ: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  TM: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  TN: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  TR: {
    regex: /^[0-9]{5}(-[0-9]{2})?$/,
    message: "must be five digits with an optional suffix of - and two more digits"
  },
  TT: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  TW: {
    regex: /^[0-9]{3}([0-9]{2})?$/,
    message: "must be three digits with an optional two more digits"
  },
  UA: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  US: {
    regex: /^\d{5}([\-]?(\d{3}|\d{4}|\d{6}))?$/,
    message:
      "must be five digits followed by an optional - and either 4 or 6 more digits"
  },
  UM: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  UY: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  UZ: {
    regex: /^[0-9]{3}( |-)?[0-9]{3}$/,
    message: "must be six digits with an optional space or - after the third digit"
  },
  VA: {
    regex: /^00120$/,
    message: "must be 00120"
  },
  VC: {
    regex: /^(VC)?[0-9]{4}$/,
    message: "must be four digits with an optional prefix of VC"
  },
  VE: {
    regex: /^[0-9]{4}( ?[A-Z])?$/,
    message: "must be four digits followed by an optional space and optional letter"
  },
  VG: {
    regex: /^(VG)?[0-9]{4}$/,
    message: "must be four digits with an optional prefix of VG"
  },
  VI: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  VN: {
    regex: /^[0-9]{6}$/,
    message: msgSix
  },
  WF: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  YT: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  },
  ZA: {
    regex: /^[0-9]{4}$/,
    message: msgFour
  },
  ZM: {
    regex: /^[0-9]{5}$/,
    message: msgFive
  }
};
