import h from "h";

function isSelector(param) {
  if (typeof param !== "string" || param.length === 0) {
    return false;
  }
  return (param[0] === "." || param[0] === "#") && param.length >= 2;
}

function mkTag(tagName) {
  return function(first, ...rest) {
    if (isSelector(first)) {
      return h(tagName + first, ...rest);
    } else {
      return h(tagName, first, ...rest);
    }
  };
}

export const a = mkTag("a");
export const abbr = mkTag("abbr");
export const address = mkTag("address");
export const area = mkTag("area");
export const article = mkTag("article");
export const aside = mkTag("aside");
export const audio = mkTag("audio");
export const b = mkTag("b");
export const base = mkTag("base");
export const bdi = mkTag("bdi");
export const bdo = mkTag("bdo");
export const blockquote = mkTag("blockquote");
export const body = mkTag("body");
export const br = mkTag("br");
export const button = mkTag("button");
export const canvas = mkTag("canvas");
export const caption = mkTag("caption");
export const cite = mkTag("cite");
export const code = mkTag("code");
export const col = mkTag("col");
export const colgroup = mkTag("colgroup");
export const dd = mkTag("dd");
export const del = mkTag("del");
export const dfn = mkTag("dfn");
export const dir = mkTag("dir");
export const div = mkTag("div");
export const dl = mkTag("dl");
export const dt = mkTag("dt");
export const em = mkTag("em");
export const embed = mkTag("embed");
export const fieldset = mkTag("fieldset");
export const figcaption = mkTag("figcaption");
export const figure = mkTag("figure");
export const footer = mkTag("footer");
export const form = mkTag("form");
export const h1 = mkTag("h1");
export const h2 = mkTag("h2");
export const h3 = mkTag("h3");
export const h4 = mkTag("h4");
export const h5 = mkTag("h5");
export const h6 = mkTag("h6");
export const head = mkTag("head");
export const header = mkTag("header");
export const hgroup = mkTag("hgroup");
export const hr = mkTag("hr");
export const html = mkTag("html");
export const i = mkTag("i");
export const iframe = mkTag("iframe");
export const img = mkTag("img");
export const input = mkTag("input");
export const ins = mkTag("ins");
export const kbd = mkTag("kbd");
export const keygen = mkTag("keygen");
export const label = mkTag("label");
export const legend = mkTag("legend");
export const li = mkTag("li");
export const link = mkTag("link");
export const main = mkTag("main");
export const map = mkTag("map");
export const mark = mkTag("mark");
export const menu = mkTag("menu");
export const meta = mkTag("meta");
export const nav = mkTag("nav");
export const noscript = mkTag("noscript");
export const object = mkTag("object");
export const ol = mkTag("ol");
export const optgroup = mkTag("optgroup");
export const option = mkTag("option");
export const p = mkTag("p");
export const param = mkTag("param");
export const pre = mkTag("pre");
export const progress = mkTag("progress");
export const q = mkTag("q");
export const rp = mkTag("rp");
export const rt = mkTag("rt");
export const ruby = mkTag("ruby");
export const s = mkTag("s");
export const samp = mkTag("samp");
export const script = mkTag("script");
export const section = mkTag("section");
export const select = mkTag("select");
export const small = mkTag("small");
export const source = mkTag("source");
export const span = mkTag("span");
export const strong = mkTag("strong");
export const style = mkTag("style");
export const sub = mkTag("sub");
export const sup = mkTag("sup");
export const table = mkTag("table");
export const tbody = mkTag("tbody");
export const td = mkTag("td");
export const textarea = mkTag("textarea");
export const tfoot = mkTag("tfoot");
export const th = mkTag("th");
export const thead = mkTag("thead");
export const title = mkTag("title");
export const tr = mkTag("tr");
export const u = mkTag("u");
export const ul = mkTag("ul");
export const video = mkTag("video");
