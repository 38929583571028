import { Promise } from "rsvp";

export function xhrPost(url, params) {
  return xhrAction("POST", url, params);
}

export function xhrPut(url, params) {
  return xhrAction("PUT", url, params);
}

export function xhrPatch(url, params) {
  return xhrAction("PATCH", url, params);
}

export function xhrDelete(url, params) {
  return xhrAction("DELETE", url, params);
}

function xhrAction(action, url, params) {
  return new Promise(function(resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open(action, url);
    xhr.onreadystatechange = function() {
      if (this.readyState == 4 && this.status > 0) {
        let data;
        try {
          data = JSON.parse(this.responseText);
        } catch (e) {
          return reject({ error: e });
        }
        if (this.status >= 200 && this.status < 400) {
          return resolve(data);
        } else {
          if (this.status == 401 && data.error_code == "login") {
            window.location.pathname = "/signin";
          }
          return reject(xhrError(this.status, xhr.statusText, data));
        }
      }
    };
    xhr.setRequestHeader("Content-type", "application/json;charset=UTF-8");
    const body = JSON.stringify(params);
    // IE 11 sends string "undefined" if body is undefined,
    // causing Rails param parsing to raise an exception
    if (body) {
      xhr.send(body);
    } else {
      xhr.send();
    }
  });
}

function xhrError(status, statusText, data) {
  if (data.error) {
    return new XhrError(status, statusText, { _error: data.error });
  } else if (Array.isArray(data.errors)) {
    return new XhrError(status, statusText, {
      _error: data.errors.join("\n")
    });
  } else if (data.errors) {
    return new XhrError(status, statusText, data.errors);
  } else {
    return new XhrError(status, statusText, { _error: statusText });
  }
}

class XhrError extends Error {
  constructor(status, statusText, errors) {
    super(statusText);
    this.status = status;
    this.statusText = statusText;
    this.errors = errors;
  }
}

export function xhrGet(url) {
  return new Promise(function(resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.onreadystatechange = function() {
      if (this.readyState == 4) {
        if (this.status >= 200 && this.status < 400) {
          const data = JSON.parse(this.responseText);
          resolve(data);
        } else if (this.status == 401) {
          window.location.pathname = "/signin";
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      }
    };
    xhr.send();
  });
}

export function xhrGetRaw(url) {
  return new Promise(function(resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.onreadystatechange = function() {
      if (this.readyState == 4) {
        if (this.status >= 200 && this.status < 400) {
          resolve(this.responseText);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
            responseText: this.responseText
          });
        }
      }
    };
    xhr.send();
  });
}

export default {
  post: xhrPost,
  get: xhrGet
};
