import cardValidator from "card-validator";

export default function cardInfo(cardNumber) {
  const validation = cardValidator.number(cardNumber || "");

  const cardInfo = validation.card || {};
  return {
    isPotentiallyValid: validation.isPotentiallyValid,
    isValid: validation.isValid,
    ...cardInfo
  };
}
