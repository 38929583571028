import React from "react";

/**
 * There are various cases of what might be passed in:
 *
 * 1. Tag + properties + string: h("p", { className: active }, "Hi there")
 * 2. Tag + properties + children: h("p", { className: active }, [ h("img"), h("img") ])
 * 3. Tag + string: h("p", "Hi there")
 * 4. Tag + children: h("p", [ h("img"), h("img") ])
 * 5. Tag + properties: h("img", { src: "/images/cat.jpg" })
 * 6. Tag on its own: h("img")
 *
 * The tag can also be a React component, e.g.,
 *   h(CountingApp, { state: 0 })
 *
 * Also, the tag can be HAML-like combinations of element, class, and ID:
 *
 *   h(".counter")
 *   h("#counter.")
 *   h("img#spinner")
 *   h("img.border")
 *   h("a#start.red.big.round")
 */
export function h(componentOrTag, properties, children) {
  const classIdSplit = /([\.#]?[a-zA-Z0-9\u007F-\uFFFF_:-]+)/;
  const notClassId = /^\.|#/;

  properties = properties || {};

  // Case 3 or 4
  if (!children && isChildren(properties)) {
    children = properties;
    properties = {};
  }

  // When a selector, parse the tag name and fill out the properties object
  if (typeof componentOrTag === "string") {
    componentOrTag = parseTag(componentOrTag, properties);
  }

  if (!componentOrTag) {
    throw new Error("Null component!");
  }

  // Create the element
  const args = [componentOrTag, properties].concat(children);
  return React.createElement.apply(React, args);

  function isChildren(x) {
    return typeof x === "string" || Array.isArray(x) || React.isValidElement(x);
  }

  function parseTag(tag, props) {
    if (!tag) {
      return "div";
    }

    const noId = !props.hasOwnProperty("id");

    const tagParts = tag.split(classIdSplit);
    let tagName = null;

    if (notClassId.test(tagParts[1])) {
      tagName = "div";
    }

    let classes, part, type, i;

    for (i = 0; i < tagParts.length; i++) {
      part = tagParts[i];

      if (!part) {
        continue;
      }

      type = part.charAt(0);

      if (!tagName) {
        tagName = part;
      } else if (type === ".") {
        classes = classes || [];
        classes.push(part.substring(1, part.length));
      } else if (type === "#" && noId) {
        props.id = part.substring(1, part.length);
      }
    }

    if (classes) {
      if (props.className) {
        classes.push(props.className);
      }

      props.className = classes.join(" ");
    }

    return props.namespace ? tagName : tagName.toLowerCase();
  }
}

export function fasIcon(iconName, props = {}) {
  return h(`i.fas.fa-${iconName}`, props);
}

export function farIcon(iconName, props) {
  return h(`i.far.fa-${iconName}`, props);
}

export function fabIcon(iconName, props) {
  return h(`i.fab.fa-${iconName}`, props);
}

export function falIcon(iconName, props) {
  return h(`i.fal.fa-${iconName}`, props);
}

export function exclamationIcon() {
  return (
    <svg
      className="hvr-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="40 00 140 140"
    >
      <path d="M117,106.8c0,0.7-0.2,1.2-0.6,1.7c-0.4,0.4-0.9,0.7-1.6,0.7h-13.6c-0.6,0-1.2-0.2-1.6-0.7c-0.5-0.5-0.7-1-0.7-1.6V93.4
      c0-0.6,0.2-1.2,0.7-1.6c0.5-0.5,1-0.7,1.6-0.7h13.6c0.6,0,1.1,0.2,1.6,0.7c0.4,0.4,0.6,1,0.6,1.7V106.8z M116.9,82.5
      c0,0.5-0.3,0.9-0.7,1.2c-0.4,0.4-1,0.5-1.7,0.5h-13.1c-0.7,0-1.2-0.2-1.7-0.5C99.3,83.4,99,83,99,82.5l-1.2-43.8
      c0-0.6,0.2-1,0.7-1.3c0.5-0.4,1-0.6,1.7-0.6h15.5c0.7,0,1.2,0.2,1.7,0.6c0.5,0.3,0.7,0.7,0.7,1.3L116.9,82.5z" />
      <g>
        <path d="M162.2,41.6c-5.6-9.6-13.2-17.2-22.8-22.8c-9.6-5.6-20.1-8.4-31.4-8.4c-11.4,0-21.8,2.8-31.4,8.4
        C67,24.4,59.4,32,53.8,41.6c-5.6,9.6-8.4,20.1-8.4,31.4c0,11.4,2.8,21.8,8.4,31.4c5.6,9.6,13.2,17.2,22.8,22.8
        c9.6,5.6,20.1,8.4,31.4,8.4s21.8-2.8,31.4-8.4c9.6-5.6,17.2-13.2,22.8-22.8c5.6-9.6,8.4-20.1,8.4-31.4
        C170.6,61.6,167.8,51.2,162.2,41.6z M146.4,95.2c-4,6.8-9.3,12.2-16.1,16.1c-6.8,4-14.2,5.9-22.2,5.9c-8,0-15.5-2-22.2-5.9
        c-6.8-4-12.2-9.3-16.1-16.1c-4-6.8-5.9-14.2-5.9-22.2s2-15.5,5.9-22.2c4-6.8,9.3-12.2,16.1-16.1s14.2-5.9,22.2-5.9s15.5,2,22.2,5.9
        c6.8,4,12.2,9.3,16.1,16.1c4,6.8,5.9,14.2,5.9,22.2S150.3,88.5,146.4,95.2z" />
      </g>
    </svg>
  );
}

export function pluralize(n, singular, plural) {
  if (n == 1) {
    return "1 " + singular;
  } else {
    if (!plural) plural = singular + "s";
    return "" + n + " " + plural;
  }
}

export function numberToCurrency(amount) {
  if (amount < 0) {
    return "-" + numberToCurrency(-amount);
  } else {
    return "$" + commify(parseFloat(amount).toFixed(2));
  }
}

export function parseDollarAmount(str) {
  const num = str.replace(/[^\d\.\-]/g, "");
  return Number(num);
}

export function commify(num) {
  return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function linkTo(label, url, props = {}) {
  return h("a", { href: url, ...props }, label);
}

export function linkToTargetBlank(label, url, props = {}) {
  return h("a", { href: url, target: "_blank", ...props }, label);
}

export function link(label, props) {
  return h("a", { href: "javascript:void(0)", ...props }, label);
}

export function titleize(value) {
  const smallWords = [
    "a",
    "an",
    "and",
    "as",
    "at",
    "but",
    "by",
    "en",
    "for",
    "if",
    "in",
    "of",
    "on",
    "or",
    "the",
    "to",
    "v",
    "v.",
    "via",
    "vs",
    "vs."
  ];
  if (typeof value !== "string") {
    return value;
  } else {
    const words = value.split(" ");
    return words
      .map(function(word, idx) {
        if (idx == 0 || smallWords.indexOf(word) == -1) {
          let charCode = word.charCodeAt(0);
          if (charCode >= 97 && charCode <= 122) {
            return String.fromCharCode(charCode - 32) + word.substring(1);
          }
        }
        return word;
      })
      .join(" ");
  }
}

export const humanize = val => titleize(val.replace(/_/g, " "));

export function fromHTML(markup, tag = "div") {
  return h(FromHTML, { tag, markup });
}

export class FromHTML extends React.Component {
  componentDidMount() {
    this.elt.innerHTML = this.props.markup;
  }

  componentDidUpdate() {
    this.elt.innerHTML = this.props.markup;
  }

  render() {
    const { tag, props = {} } = this.props;
    return h(tag, { ref: e => (this.elt = e), ...props }, []);
  }
}

export function gaTrackEvent(category, action, label, value) {
  const ga = window.ga;
  if (ga) {
    let data = {
      hitType: "event",
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    };

    if (value) {
      data.eventValue = value;
    }

    ga("send", data);
  }
}

export function updateHeaderCartItemCount(count) {
  if (count > 0) {
    $(".shopping_cart_menu").show();
    $(".shopping_cart_size").html(count);
    const newNavCart = $(".nav__cart");
    if (newNavCart) {
      newNavCart.show();
      newNavCart.find(".cart__count").html(cartText(count))
    }
  } else {
    $(".shopping_cart_menu").hide();
    $(".shopping_cart_size").html(0);
    const newNavCart = $(".nav__cart");
    if (newNavCart) {
      newNavCart.hide();
      newNavCart.find(".cart__count").html(0)
    }
  }
}

function cartText(count) {
  return count > 1 ? `${count} Items` : "1 Item"
}

export default h;
