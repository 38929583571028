import validate from "validate.js";
import moment from "moment";
import ipaddr from "ipaddr.js";
import cardInfo from "../util/cardInfo";
import validatePostalCode from "./validatePostalCode";
import validateCNAME from "./validateCNAME";
import validateDomain from "./validateDomain";
import validateHex from "./validateHex";
import validateAuthCode from "./validateAuthCode";
import { isValidCiraName } from "./cira";
import validateCurrency from "./validateCurrency";

let initialized = false;

function init() {
  validate.options = { fullMessages: false };
  validate.validators.presence.options = { message: "Required" };
  validate.validators.email.options = { message: "Not valid" };
  validate.validators.length.options = {
    tooLong: "Too long",
    tooShort: "Too short"
  };

  validate.validators.asciiOnly = function(value) {
    const result = /[^\040-\177]/.exec(value);
    if (result) {
      return `Character ${result[0]} not allowed`;
    }
  };

  validate.validators.alphanumeric = function(value) {
    const result = /^[a-z0-9]+$/i.exec(value);
    if (!result) {
      return "Must be alphanumeric";
    }
  };

  validate.validators.validCardNumber = function(value) {
    const card = cardInfo(value);
    if (!card.isValid) {
      return "Not valid";
    }
  };

  validate.validators.future = function(_value, _options, _key, attributes) {
    const mm = attributes.expiry_month;
    const yyyy = attributes.expiry_year;

    if (!mm || !yyyy) {
      return;
    }

    const today = moment();
    const expMonthStart = moment({ year: yyyy, month: mm - 1, day: 1 });
    const expMonthEnd = expMonthStart.endOf("month");

    if (expMonthEnd.isBefore(today)) {
      return "Not valid";
    }
  };

  validate.validators.checked = function(value) {
    if (!value) {
      return "Required";
    }
  };

  validate.validators.postalCode = function(value, _options, _key, attributes) {
    if (value) {
      const result = validatePostalCode(attributes.country, value);
      if (result && result.valid == false) {
        return "Not valid";
      }
    }
  };

  validate.validators.cname = function(value) {
    if(value) {
      if(!validateCNAME(value)) {
        return "Not a valid hostname"
      }
      if(value.length > 253) {
        return "Too long"
      }
    } else {
      return "Required"
    }
  }

  validate.validators.domain = function(value) {
    if (value) {
      if (!validateDomain(value)) {
        return "Not valid";
      }
    }
  };

  validate.validators.ipv4 = function(value) {
    if (value) {
      if (!ipaddr.IPv4.isValid(value)) {
        return "Not valid IPv4";
      }
    }
  };

  validate.validators.ipv6 = function(value) {
    if (value) {
      if (!ipaddr.IPv6.isValid(value)) {
        return "Not valid IPv6";
      }
    }
  };

  validate.validators.ipvx = function(value) {
    if (value) {
      if (!ipaddr.isValid(value)) {
        return "Not valid";
      }
    }
  };

  validate.validators.braintreeProxy = function(value) {
    if (value && value !== "valid") {
      return "Not valid";
    }
  };

  validate.validators.validCiraName = function(value, options) {
    if (!isValidCiraName(value, options.legalType)) {
      return "Not valid";
    }
  };
  validate.validators.conditionalRequirement = function(
    value,
    options,
    key,
    attributes
  ) {
    if (
      attributes[options.guardField] == options.conditonalValue &&
      (value == "" || value == null)
    ) {
      return "Required";
    }
  };

  validate.validators.hasLetter = function(value) {
    if (!validateAuthCode.hasLetter(value)) {
      return "Requires at least 1 letter";
    }
  };

  validate.validators.hasUpperCaseLetter = function(value) {
    if (!validateAuthCode.hasUpperCaseLetter(value)) {
      return "Requires at least 1 upper case letter";
    }
  };

  validate.validators.hasLowerCaseLetter = function(value) {
    if (!validateAuthCode.hasLowerCaseLetter(value)) {
      return "Requires at least 1 lower case letter";
    }
  };

  validate.validators.hasNumber = function(value) {
    if (!validateAuthCode.hasNumber(value)) {
      return "Requires at least 1 number";
    }
  };

  validate.validators.hasSpecialCharacter = function(value) {
    if (!validateAuthCode.hasSpecialCharacter(value)) {
      return "Requires at least 1 special character";
    }
  };

  validate.validators.noSpecialCharacter = function(value) {
    if (validateAuthCode.hasSpecialCharacter(value)) {
      return "Can't have special characters";
    }
  };

  validate.validators.hex = function(value) {
    if (!validateHex(value)) {
      return "Must be a hexadecimal value";
    }
  };

  validate.validators.password = function(value) {
    if (!value) return "Required"
    const requirements = [
      v => v.length < 14 ? "Too short" : null,
      validate.validators.hasUpperCaseLetter,
      validate.validators.hasLowerCaseLetter,
      validate.validators.hasNumber,
      validate.validators.hasSpecialCharacter
    ];
    return requirements.filter(r => r(value));
  };

  validate.validators.currency = function(value) {
    if(!validateCurrency(value)) {
      return "Must be a valid dollar amount"
    }
  }

  validate.validators.mailboxName = function(value) {
    if(!value) {
      return "Required";
    }

    if (value.length > 64) {
      return "Too long";
    }

    if(!/^[a-z0-9._-]+$/i.test(value)) {
      return "Contains invalid characters. You can use A-Z, 0-9, hyphen (-), underscore (_), and dot(.)";
    }

    if(/\.\./.test(value)) {
      return "Cannot contain consecutive dots";
    }

    if(/\.$/.test(value)) {
      return "Cannot end with a dot";
    }

    if(/^[._-]/.test(value)) {
      return "Must begin with A-Z or 0-9";
    }
  }

  validate.validators.duplicate_mbox_check = function(value, mboxNames) {
    if(mboxNames && mboxNames.includes(value)) {
      return "A mailbox with that name already exists";
    }
  }

  validate.validators.verificationCode = function(value) {
    if (!value) return "Required";
    return [
      (v) => validate.validators.length(v, {
        minimum: 6,
        maximum: 6,
      }),
      (v) => validate.validators.numericality(v, {
        onlyInteger: true,
      })
    ].filter(r => r(value));
  }

  validate.validators.srvName = function(value) {
    if (!value) return "Required";

    if (!(/^[_]/.test(value))) {
      return "Must begin with an _ (underscore)";
    }
  }

  initialized = true;
}

export default function(values, constraints, options) {
  if (!initialized) {
    init();
  }

  return validate(values, constraints, options) || {};
}
