import { createSelector } from "reselect";

export function isValidCiraName(name, legalType) {
  // CIRA requires individuals to have individual names,
  // but does not require organizations to have organization names.
  if (isIndividualType(legalType)) {
    return isIndividualName(name);
  } else {
    return true;
  }
}

export function isIndividualType(type) {
  return individualTypes.has(type);
}

export function isOrganizationType(type) {
  return type && !isIndividualType(type);
}

const notIndividual = createSelector(
  config => config.orgWords,
  words => makeRegex(words)
);

function removeOperatingAs(name) {
  return name.replace(/o\/a.*/i, "");
}

export function isIndividualName(name, config = ciraConfig) {
  name = removeOperatingAs(name);
  return hasFirstAndLast(name) && !name.match(notIndividual(config));
}

const hasFirstAndLast = name => name.match(/\w.*\s.*\w/) !== null;

const orgRegexp = createSelector(
  config => config.companyWords,
  config => config.jurisdictions,
  (w1, w2) => makeRegex([...w1, ...w2])
);

export function isOrganizationName(name, config = ciraConfig) {
  return (
    !!name.match(orgRegexp(config)) ||
    name
      .toLowerCase()
      .split(/\s+/)
      .some(word => config.extra.includes(word))
  );
}

const individualTypes = new Set(["CCT", "RES", "ABO", "LGR"]);

const ciraConfig = {
  orgWords: [
    "academic",
    "academie",
    "academy",
    "access",
    "admin",
    "administration",
    "administrations",
    "administrator",
    "adult",
    "adventure",
    "alb",
    "alberta",
    "alliance",
    "american",
    "and",
    "animal",
    "animals",
    "animaux",
    "arts",
    "assistance",
    "associate",
    "associates",
    "association",
    "authority",
    "autorite",
    "available",
    "band",
    "bc",
    "breakfast",
    "british",
    "business",
    "businesses",
    "calgary",
    "canada",
    "canadian",
    "canadien",
    "canadienne",
    "capital",
    "capitale",
    "care",
    "carpet",
    "carpets",
    "central",
    "city",
    "classic",
    "club",
    "clubs",
    "co-op",
    "college",
    "comission",
    "commerce",
    "communication",
    "communications",
    "compagnie",
    "company",
    "computer",
    "conseiller",
    "conseillers",
    "consultant",
    "consultants",
    "consultation",
    "consulting",
    "coop",
    "corporation",
    "cottages",
    "council",
    "country",
    "cyber",
    "cybercafe",
    "design",
    "designs",
    "development",
    "developpement",
    "digital",
    "disabilities",
    "disability",
    "disponible",
    "distribution",
    "district",
    "dns",
    "domain",
    "domaine",
    "domaines",
    "domains",
    "drink",
    "droits",
    "economic",
    "economique",
    "educational",
    "eglise",
    "electronic",
    "electronics",
    "electronique",
    "electroniques",
    "entreprise",
    "ergonomic",
    "ergonomics",
    "ergonomique",
    "essential",
    "essentials",
    "essentiel",
    "essentiels",
    "evenement",
    "evenements",
    "event",
    "events",
    "farm",
    "farms",
    "ferme",
    "fermes",
    "film",
    "films",
    "financial",
    "financiere",
    "food",
    "foreign",
    "free",
    "fund",
    "funds",
    "gallerie",
    "gestion",
    "golf",
    "graphic",
    "graphics",
    "graphique",
    "graphiques",
    "group",
    "groupe",
    "halifax",
    "hamlet",
    "health",
    "hebdomadaire",
    "help",
    "hosting",
    "hotel",
    "hotels",
    "immobilier",
    "individu",
    "individual",
    "individuals",
    "individus",
    "industrie",
    "industries",
    "industry",
    "information",
    "instruments",
    "interest",
    "international",
    "internet",
    "journal",
    "laboratoire",
    "laboratoires",
    "laboratories",
    "laboratory",
    "leasing",
    "liberal",
    "local",
    "location",
    "locations",
    "management",
    "manitoba",
    "mb",
    "mechanic",
    "mechanics",
    "medecine",
    "media",
    "medical",
    "montreal",
    "motel",
    "motels",
    "motoneige",
    "motoneiges",
    "motor",
    "motors",
    "nb",
    "network",
    "networking",
    "new",
    "new brunswick",
    "newfoundland",
    "nf",
    "northerns",
    "nova",
    "nova scotia",
    "of",
    "office",
    "online",
    "ontario",
    "org.",
    "organisation",
    "organization",
    "ottawa",
    "outlet",
    "outlets",
    "pei",
    "photographie",
    "photography",
    "picture",
    "pictures",
    "product",
    "production",
    "productions",
    "products",
    "program",
    "programs",
    "properties",
    "property",
    "public",
    "publishing",
    "punk",
    "quarterly",
    "quebec",
    "realty",
    "remote",
    "rental",
    "rentals",
    "restaurant",
    "rights",
    "rues",
    "rural",
    "sa",
    "sask.",
    "saskatoon",
    "school",
    "scotia",
    "services",
    "sex",
    "ski",
    "snowmobible",
    "snowmobibles",
    "societe",
    "society",
    "solution",
    "solutions",
    "sport",
    "sports",
    "student",
    "studio",
    "supplies",
    "supply",
    "system",
    "systems",
    "teacher",
    "teachers",
    "teaching",
    "technical",
    "technologie",
    "technology",
    "the",
    "theatre",
    "tm",
    "toronto",
    "town",
    "treats",
    "trimestriel",
    "ultimate",
    "universal",
    "universel",
    "universite",
    "university",
    "vacation",
    "vacations",
    "vancouver",
    "vente",
    "verte",
    "village",
    "ville",
    "virtual",
    "virtuel",
    "webmaster",
    "website",
    "weekly",
    "winnipeg",
    "xxx"
  ],

  companyWords: [
    "inc",
    "incorporated",
    "co",
    "corp",
    "corporation",
    "ltd",
    "limited",
    "ltée"
  ],

  jurisdictions: [
    "canada",
    "british columbia",
    "alberta",
    "saskatchewan",
    "manitoba",
    "ontario",
    "quebec",
    "québec",
    "new brunswick",
    "nova scotia",
    "newfoundland",
    "yukon",
    "nunavut",
    "northwest territories",
    "bc",
    "ab",
    "sk",
    "mb",
    "on",
    "qc",
    "nb",
    "ns",
    "nf",
    "yk",
    "nv",
    "nt",
    "nwt"
  ],

  extra: ["b.c."]
};

function makeRegex(words) {
  return new RegExp("\\b(" + words.map(escapeRegexp).join("|") + ")\\b", "i");
}

// http://stackoverflow.com/a/6969486/183140
function escapeRegexp(str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}
